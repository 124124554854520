<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>FaQ</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              @onDelete="doDelete"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidDelete="forbidDel"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table
  },
  data() {
    return {
      headers: {
        kategori: {
          label: "Kategori",
          sortable: true
        },
        keterangan: {
          label: "Keterangan",
          sortable: true
        },
        updated_at: {
          label: "Tanggal Update",
          sortable: true,
          type:"date"
        }
      }
    };
  },
  computed: {
    items() {
      return this.$store.state.faqUser.items ? this.$store.state.faqUser.items.items : [];
    },
    filters() {
      return this.$store.state.faqUser.items ? this.$store.state.faqUser.items.filters: {};
    },
    state() {
      return this.$store.state.faqUser;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.faq_user){
        return !this.$store.state.profile.permissions.faq_user.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.faq_user){
        return !this.$store.state.profile.permissions.faq_user.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.faq_user){
        return !this.$store.state.profile.permissions.faq_user.delete;
      }
      return false;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: []
    };
    this.$store.commit("faqUser/STATE", state);
    this.get(state);
  },
  methods: {
    get(val) {
      this.$store.dispatch("faqUser/getFaQUser", val);
    },
    doDetail(val) {
      this.$store.dispatch("faqUser/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("faqUser/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("faqUser/submitDelete", val);
    }
  }
};
</script>
